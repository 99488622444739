
<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'safety-org-chart',
  data() {
    return {
      searchUrl: '',
      tab: 'status',
      tabItems: [
        { name: 'status', icon: 'graph', label: '안전보건관리체제 조직도', component: () => import(`${'./safetyOrgChartTemp.vue'}`) }, 
        { name: 'master', icon: 'list', label: '조직도 만들기', component: () => import(`${'./safetyOrgMst.vue'}`) }, 
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
